.subscription-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 2rem;
  flex-wrap: wrap;
}

.image-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.sale-image {
  width: 100%;
  height: auto;
}

.form-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px;
  padding: 1rem;
}

.success-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.2rem;
  color: #4caf50;
  text-align: center;
}

.form-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
  text-align: center;
}

.subscription-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.input-wrapper {
  width: 100%;
}

.input-field {
  width: calc(100% - 20px);
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid hsl(0, 0%, 87%);
  border-radius: 20px;
  font-size: 1rem;
  box-sizing: border-box;
}

.submit-button {
  width: fit-content;
  padding: 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.submit-button:hover:enabled {
  background-color: #45a049;
}



.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  max-width: 500px;
  width: 80%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  z-index: 1000;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.close-button {
  margin-top: 10px;
  padding: 10px 20px;
  background: #007BFF;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.close-button:hover {
  background: #0056b3;
}


@media only screen and (max-width: 768px) {
  .subscription-container {
    flex-direction: column;
  }

  .image-container,
  .form-container {
    width: 100%;
    padding: 0.5rem;
  }

  .sale-image {
    display: none;
  }

  .input-field {
    width: calc(100% - 20px);
  }
}



