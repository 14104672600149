/* slider.css */

.s-container {
    width: 100%;
    max-width: 1200px;
    margin: 2rem auto;
    padding: 0 15px;
    box-sizing: border-box;
  }
  
  /* Swiper container */
  .mySwiper {
    padding-bottom: 40px; /* Space for pagination */
  }
  
  /* Card structure with fixed dimensions */
  .slide-card {
    width: 100%;
    height: 350px; /* Fixed height */
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  
  /* Image section with fixed height */
  .slide-image-wrapper {
    width: 100%;
    height: 200px; /* Fixed height for image area */
    overflow: hidden;
  }
  
  .slide-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }
  
  /* Info section with fixed height */
  .slide-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0; /* No padding on container */
    height: 150px; /* Fixed height for info area */
  }
  
  /* Title section with fixed height */
  .slide-title-area {
    height: 40px; /* Fixed height */
    padding: 10px 15px 0;
    overflow: hidden;
  }
  
  .slide-title {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    color: #333;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  /* Description section with fixed height */
  .slide-description-area {
    height: 50px; /* Fixed height */
    padding: 0 15px;
    overflow: hidden;
  }
  
  .slide-description {
    margin: 0;
    font-size: 14px;
    color: #666;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 1.4;
  }
  
  /* Button section with fixed height */
  .slide-button-area {
    height: 60px; /* Fixed height */
    padding: 15px;
    display: flex;
    align-items: center;
  }
  
  .slide-button {
    display: inline-block;
    padding: 8px 16px;
    background-color: #004aad;
    color: white;
    border-radius: 4px;
    text-decoration: none;
    font-size: 14px;
    transition: background-color 0.3s;
    text-align: center;
    font-weight: 500;
  }
  
  .slide-button:hover {
    background-color: #003880;
  }
  
  /* Navigation arrows */
  .swiper-button-next,
  .swiper-button-prev {
    color: #004aad;
    background-color: rgba(255, 255, 255, 0.8);
    width: 35px;
    height: 35px;
    border-radius: 50%;
    --swiper-navigation-size: 20px;
  }
  
  .swiper-button-next:hover,
  .swiper-button-prev:hover {
    background-color: rgba(255, 255, 255, 0.95);
  }
  
  /* Pagination bullets */
  .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    background-color: #ccc;
    opacity: 1;
  }
  
  .swiper-pagination-bullet-active {
    background-color: #004aad;
  }
  
  /* Responsive adjustments - only adjusting specific elements while maintaining fixed structure */
  @media screen and (max-width: 768px) {
    .slide-card {
      height: 330px; /* Slightly shorter on tablets */
    }
    
    .slide-image-wrapper {
      height: 180px;
    }
    
    .slide-info {
      height: 150px;
    }
  }
  
  @media screen and (max-width: 480px) {
    .slide-card {
      height: 320px; /* Even shorter on mobile */
    }
    
    .slide-image-wrapper {
      height: 170px;
    }
    
    .slide-info {
      height: 150px;
    }
    
    .slide-button {
      padding: 6px 14px;
      font-size: 13px;
    }
    
    .swiper-button-next,
    .swiper-button-prev {
      display: none;
    }
  }