.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000000000000000;
}

.modalContent {
    background: rgba(255, 255, 255, 0.795);
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    max-width: 600px;
    position: relative;
    color: #004aad;
    text-align: left;
}

.modalBody {
    max-height: 60vh;
    overflow-y: auto;
    margin-bottom: 20px;
}

.closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: transparent;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    color: #004aad;
}

.closeButton:hover {
    color: #002b70;
}
