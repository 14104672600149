/* Reset and base styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  overflow-x: hidden;
}

.product-page {
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
}

.content {
  padding: 1.5rem;
  max-width: 1200px;
  margin: 0 auto;
  overflow-x: hidden;
}

.product-container {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  width: 100%;
}

/* Minimalist card design */
.product-card {
  display: flex;
  position: relative;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  width: 100%;
  margin: 0;
  overflow: hidden;
}

.card-slider {
  width: 50%;
  padding-right: 1.5rem;
}

.card-text {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Typography */
.product-card h2 {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 1rem;
  color: #333;
}

.product-card p {
  font-size: 0.95rem;
  line-height: 1.5;
  color: #666;
  margin-bottom: 1.5rem;
}

/* Image styling */
.product-card img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 2px;
}

/* Gallery link */
.gallery {
  margin-top: auto;
}

.gallery-link {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  color: #555;
  font-size: 0.9rem;
  transition: color 0.2s ease;
}

.gallery-link:hover {
  color: #000;
}

.arrow {
  margin-left: 0.5rem;
  font-size: 0.8rem;
}

/* Slider customization */
.slick-dots {
  bottom: -25px;
}

.slick-dots li button:before {
  font-size: 8px;
  opacity: 0.3;
}

.slick-dots li.slick-active button:before {
  opacity: 0.7;
}

/* Media queries */
@media screen and (max-width: 900px) {
  .product-card {
    padding: 1.25rem;
  }
  
  .card-slider {
    padding-right: 1.25rem;
  }
  
  .product-card h2 {
    font-size: 1.3rem;
  }
  
  .product-card img {
    height: 250px;
  }
}

@media screen and (max-width: 768px) {
  .product-card {
    flex-direction: column;
    padding: 1rem;
  }
  
  .card-slider, .card-text {
    width: 100%;
    padding-right: 0;
  }
  
  .card-text {
    margin-top: 1.5rem;
  }
  
  .product-card h2 {
    margin-bottom: 0.75rem;
  }
  
  .product-card p {
    margin-bottom: 1.25rem;
  }
}

@media screen and (max-width: 480px) {
  .content {
    padding: 1rem;
  }
  
  .product-container {
    gap: 1.5rem;
  }
  
  .product-card {
    padding: 0.75rem;
  }
  
  .product-card h2 {
    font-size: 1.2rem;
  }
  
  .product-card p {
    font-size: 0.9rem;
  }
  
  .product-card img {
    height: 200px;
  }
}

@media screen and (max-width: 320px) {
  .content {
    padding: 0.75rem;
  }
  
  .product-card {
    padding: 0.5rem;
  }
}