.cFooterWrapper {
    max-width: 100%;
    overflow-x: hidden;
    background-color: #004aad;
    margin-top: 2rem;
    color: aliceblue;
    padding: 0 1rem; /* Add padding for better mobile layout */
}

.cFooterWrapper > hr {
    width: 100%;
    height: 1px;
    border: none;
    background: #004aad;
    margin-top: 1rem;
    padding: 1rem;
}

.cFooter {
    display: flex;
    flex-direction: column; /* Change to column layout for mobile */
    align-items: center; /* Center items horizontally */
}

.logomain {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.logo > img {
    width: 5rem;
    height: 5rem;
    padding: 0.6rem;
}

.logoname {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logoname > span:first-child {
    font-weight: 600;
    font-family: 'Lexend Zetta', sans-serif;
}

.logoname > span:nth-child(2) {
    font-weight: 500;
    font-size: 0.8rem;
    letter-spacing: 2px;
}

.block {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    max-width: 15rem;
    padding: 2rem;
    text-align: center; /* Center text for mobile */
}

.detail {
    display: flex;
    flex-direction: column;
    width: inherit;
    font-size: 0.8rem;
    gap: 1rem;
    font-style: italic;
}

.detail > span:nth-of-type(1) {
    font-weight: 500;
    font-style: normal;
    font-size: 1.2rem;
}

.pngLine:hover {
    cursor: pointer;
}

.pngLine {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
}

.icon {
    width: 1.2rem;
    height: 1.2rem;
}

.copyRight {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    font-size: 0.8rem;
    margin-top: 2rem;
}

/* Tablet (min-width: 668px) */
@media (min-width: 668px) {
    .cFooter {
        flex-direction: row; /* Change back to row layout for tablets */
        justify-content: space-around; /* Keep items evenly spaced */
        align-items: flex-start; /* Align items to the start of the cross axis */
    }

    .block {
        max-width: 20rem;
        text-align: left; /* Align text to left for tablet and above */
    }

    .pngLine {
        justify-content: flex-start; /* Align items to the start */
    }
}

/* Desktop (min-width: 992px) */
@media (min-width: 992px) {
    .block {
        max-width: -webkit-fit-content;
        max-width: -moz-fit-content;
        max-width: fit-content;
    }

    .info {
        display: flex;
        max-width: fit-content;
        flex-direction: row;
    }
}
