.c-container {
    padding-left: 8vw;
    padding-right: 8vw;
    display: flex;
    flex-direction: column;
  }
  
.c-title {
    font-weight: 600;
    margin-bottom: 2rem;
    font-size: 5vw;
    border-radius: 3vw;
    text-align: center;
    background-color: #004aad;
    background-size: cover;
    background-position: center;
    color: white;
    padding: 3vw;
  }
.contact-details {
    display: flex;
    flex-wrap: wrap;
}

.detail {
    flex: 1;
    margin-bottom: 2rem;
    font-size: 1.7vw;
    text-align: left; /* Align text on the left side */
}
.detail > span {
    display: flex; /* Make spans flex containers */
    align-items: center; /* Align items vertically */
}

.map {
    flex: 1;
}

.icon {
    width: 2rem !important;
    height: 2rem !important;
    padding: 0.5rem;
    display: flex;
    gap: 1rem;
    align-items: center;

}


@media screen and (max-width: 992px) {
    .detail{
        font-size: 3vw;
    }
}