.carousel {
    height: 50vh;
    width: 100%;
    overflow: hidden;
    position: relative;
    background-color: #f8f8f8;
}

.carousel .list {
    height: 100%;
    width: 100%;
    position: relative;
}

.carousel .list .item {
    width: 100%;
    height: 100%;
    position: absolute;
    transition: opacity 0.8s ease;
    opacity: 0;
}

.carousel .list .item.active {
    opacity: 1;
}

.carousel .list .item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.carousel .list .item .content {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 2rem;
    box-sizing: border-box;
    background: linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 100%);
    color: #ffffff;
}

.carousel .list .item .author {
    font-size: 0.8rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 0.5rem;
    opacity: 0.8;
}

.carousel .list .item .topic {
    font-size: 1.8rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
}

.carousel .list .item .des {
    font-size: 1rem;
    max-width: 600px;
    line-height: 1.5;
    margin-bottom: 1.5rem;
    opacity: 0.9;
}

.carousel .list .item .buttons {
    display: flex;
    gap: 1rem;
}

.carousel .list .item .buttons button {
    background: transparent;
    border: 1px solid #ffffff;
    color: #ffffff;
    padding: 0.6rem 1.2rem;
    font-size: 0.8rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.3s ease;
}

.carousel .list .item .buttons button:hover {
    background: #ffffff;
    color: #000000;
}

.arrows {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;
    box-sizing: border-box;
    z-index: 10;
}

.arrows button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.3);
    border: none;
    color: #ffffff;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease;
}

.arrows button:hover {
    background-color: rgba(255, 255, 255, 0.8);
    color: #000000;
}

/* Indicator dots */
.indicators {
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 0.5rem;
    z-index: 10;
}

.indicators .dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.5);
    cursor: pointer;
    transition: all 0.3s ease;
}

.indicators .dot.active {
    background-color: #ffffff;
    transform: scale(1.2);
}

/* Responsive styles */
@media only screen and (max-width: 992px) {
    .carousel {
        height: 60vh;
    }
    
    .carousel .list .item .content {
        padding: 1.5rem;
    }
    
    .carousel .list .item .topic {
        font-size: 1.5rem;
    }
    
    .carousel .list .item .des {
        font-size: 0.9rem;
    }
}

@media only screen and (max-width: 768px) {
    .carousel {
        height: 70vh;
    }
    
    .carousel .list .item .content {
        padding: 1.2rem;
    }
    
    .carousel .list .item .topic {
        font-size: 1.3rem;
    }
    
    .carousel .list .item .des {
        font-size: 0.85rem;
        -webkit-line-clamp: 4;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    
    .arrows button {
        width: 34px;
        height: 34px;
        font-size: 1rem;
    }
}

@media only screen and (max-width: 480px) {
    .carousel {
        height: 80vh;
    }
    
    .carousel .list .item .content {
        padding: 1rem;
    }
    
    .carousel .list .item .author {
        font-size: 0.7rem;
    }
    
    .carousel .list .item .topic {
        font-size: 1.1rem;
    }
    
    .carousel .list .item .des {
        font-size: 0.8rem;
        -webkit-line-clamp: 3;
    }
    
    .carousel .list .item .buttons button {
        padding: 0.5rem 1rem;
        font-size: 0.7rem;
    }
    
    .arrows button {
        width: 30px;
        height: 30px;
    }
}